/* eslint-disable no-unused-vars */
import { AppBar, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { WithErrorHandler, WithErrorHandlerProps } from "../../WithErrorHandler";
import { getAllActivities, getCheckOutAutomationWithEvent } from "../../../constant/api";
import CheckoutAutomation from "./CheckoutAutomation";
import { Colors } from "../../../theme/Colors";
import CommonHeader from "../../UI-Components/CommonHeader";
import history from "../../../history";

interface Props extends WithErrorHandlerProps, WithStyles<typeof styles> {
  readonly notify: (arg1: string, arg2: string) => void;
}

const Workflows = ({ classes, errorHandler, notify }: Props) => {
  const [checkoutAutomation, setCheckoutAutomation] = useState<CheckOutAutomationWithEvent[]>([]);
  const [eventList, setEventList] = useState<EventList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAutomationAndEvents = async () => {
    try {
      setLoading(true);
      const {
        data: { item },
      } = await getCheckOutAutomationWithEvent();
      setCheckoutAutomation(item);
      const eventsResponse = await getAllActivities();
      const events = eventsResponse.data.item.filter(
        (event) => event.publishStatus && !event.isDraft && event.isCheckOutReq,
      );
      setEventList(events);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAutomationAndEvents();
  }, []);

  const handleClick = () => {
    history.push("/create-automation-checkout");
  };

  return (
    <div className="tabs_container">
      <AppBar position="static" className="appbar app_bar">
        <CommonHeader
          buttonText="Create automation"
          handleClick={handleClick}
          name="Workflows"
          text="Workflows can be created to add on to Activities. A Workflow on an Activity automates check-outs for people."
          testId="workflows"
        />
      </AppBar>
      <h4 className={classes.title}>Automate Check-outs</h4>
      <CheckoutAutomation
        checkoutAutomations={checkoutAutomation}
        events={eventList}
        isLoadingAutomationData={loading}
        notify={notify}
      />
    </div>
  );
};

const styles = () =>
  createStyles({
    title: {
      backgroundColor: Colors.white,
      padding: "10px 0",
      textAlign: "center",
      textTransform: "uppercase",
      width: "104%",
    },
  });

export default withStyles(styles)(WithErrorHandler(Workflows));
