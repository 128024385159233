// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../../store/store";
import { Button, Card, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import Cookies from "js-cookie";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import React from "react";
import { TermsOfServiceModal } from "../TermsOfServiceModal";
import { Timer } from "./Layout/Timer";
import defaultOrg from "../../assets/Images/Icons/defaultOrg.svg";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogin } from "../../hooks/auth";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginBottom: "3px",
    marginTop: "22px",
    textAlign: "left",
  },
  activityText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    textAlign: "left",
    width: "327px",
    wordBreak: "break-all",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "72px",
    letterSpacing: "1.25px",
    lineHeight: "20px",
    textAlign: "left",
    textTransform: "uppercase",
    width: "263px",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    marginTop: "23px",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "3px",
    textAlign: "left",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "16px",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  guestCheckinButtonWrapper: {
    background: Colors.gray,
    border: `1px solid ${Colors.imageContainerBorder}`,
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "0.15px",
    textAlign: "left",
    textTransform: "uppercase",
    width: "263px",
  },
  meritText: {
    color: Colors.darkBlue,
    cursor: "pointer",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    marginTop: "15px",
    textAlign: "left",
  },
  mobileAppText: {
    fontSize: "12px",
    opacity: 0.5,
    paddingLeft: "38px",
  },
});

interface LocationState {
  eventData: {
    readonly active: boolean;
    readonly createdAt: string;
    readonly createdBy?: null;
    readonly event: eventName;
    readonly eventId: number;
    readonly kioskEventId: number;
    readonly kioskId: number;
    readonly sequence?: null;
    readonly updatedAt: string;
    readonly updatedBy?: null;
    readonly visible: boolean;
  };
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const CheckInWith = ({ location }: Props | any) => {
  const { isAuthenticated } = useAuth0();
  const intermediateScreen = useStore((state: AppState) => state.intermediateScreen);
  const setIntermediateScreen = useStore((state: AppState) => state.setIntermediateScreen);

  const promptLogin = useLogin();
  const { Content } = Layout;
  const { push, goBack } = history;
  const eventData = location.state.eventData ?? JSON.parse(localStorage.getItem("eventData") ?? "{}");

  const virtualKioskType = Cookies.get("virtualKioskType");
  const homePage = localStorage.getItem("homePage");
  const name = localStorage.getItem("name");
  const logoUrl = localStorage.getItem("logoUrl");

  localStorage.setItem("eventData", JSON.stringify(eventData));
  localStorage.setItem("logInWith", "checkIn");

  const handleLogin = async () => {
    try {
      await promptLogin();
    } catch (err: unknown) {
      push("/web-checkin/home");
    }
  };

  const hasPolicy = (policyIds: string) => {
    if (policyIds && policyIds.trim().length > 0) {
      return true;
    }

    return false;
  };

  if (isAuthenticated && intermediateScreen === "termsOfService") {
    return <TermsOfServiceModal />;
  }

  if (isAuthenticated && intermediateScreen === "selectOrg") {
    setIntermediateScreen(undefined);
    push("/web-checkin/validate-member-login");

    return null;
  }

  return (
    <React.Fragment>
      {homePage === "checkInWith" ? (
        <Header
          orgLogo={logoUrl ? logoUrl : defaultOrg}
          exitButton={false}
          backArrow={false}
          orgName={name ? name : ""}
        />
      ) : (
        <div>
          <Timer />
          <Header backArrow exitButton handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
        </div>
      )}
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <p className={css(styles.activityText)} data-testid="activityName-CheckInWith">
                {eventData.event.name}
              </p>
              <p className={css(styles.checkinText)} data-testid="checkInText-CheckInWith">
                How do you want to check-in ?
              </p>
              <div className="checkin_with_card">
                <Card className={css(styles.cardWrapper)}>
                  <div>
                    <h3 className={css(styles.accountText)}>With my Merit account</h3>
                    {virtualKioskType === "1" && (
                      <div style={{ marginTop: "10px" }}>
                        <Button
                          className={css(styles.buttonWrapper)}
                          type="primary"
                          icon="qrcode"
                          size="large"
                          onClick={() => {
                            push({ pathname: "/web-checkin/qr-scan", state: { checkIn: true } });
                          }}
                        >
                          scan my merit key
                          <br />
                          <span className={css(styles.mobileAppText)}>via mobile app</span>
                        </Button>
                      </div>
                    )}
                    <div style={{ marginTop: "16px" }}>
                      <Button
                        className={css(styles.buttonWrapper)}
                        onClick={handleLogin}
                        type="primary"
                        icon="mail"
                        size="large"
                        data-testid="loginWithEmailPasswordButton-CheckInWith"
                      >
                        login with email &amp; <br /> <span style={{ paddingLeft: "38px" }}>password</span>
                      </Button>
                    </div>
                    {!hasPolicy(eventData.event.policyIds) && (
                      <>
                        <h3 className={css(styles.accountText)}>As a guest</h3>
                        <div style={{ marginTop: "3px" }}>
                          <Button
                            className={css(styles.guestCheckinButtonWrapper)}
                            type="primary"
                            icon="user"
                            size="large"
                            onClick={() => {
                              push("/web-checkin/guestcheckin");
                            }}
                            data-testid="checkinAsGuestButton-CheckInWith"
                          >
                            check-in as a guest
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </div>
              <h4
                className={css(styles.meritText)}
                onClick={() => {
                  push("/web-checkin/aboutmerit");
                }}
                data-testid="whatIsMeritLink-CheckInWith"
              >
                What is Merit?
              </h4>
            </Row>
          </Row>
        </div>
      </Content>
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(CheckInWith);
