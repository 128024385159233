/* eslint-disable no-unused-vars */
import {
  AppBar,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  AutomationCheckoutRule,
  AutomationRadioGroup,
  CheckoutAutomationType,
  TimeInterval,
  TimeUnitOption,
  WeeklySchedule,
} from "../../../../types/workflow";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createCheckOutAutomation, updateCheckOutAutomation } from "../../../../constant/api";
/* eslint-enable no-unused-vars */
import ConfirmationBox from "../../../UI-Components/Confirmation";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import { None } from "../../../../utils/None";
import { Some } from "../../../../utils/Some";
import { Tooltip } from "antd";
import history from "../../../../history";
import moment from "moment";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";

interface locationType {
  data: {
    action?: string;
    checkOutAutomation?: CheckoutAutomationType;
  };
}

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{}, {}, locationType> & {
    readonly notify: (arg1: string, arg2: string) => void;
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
    },
  },
};

const defaultValue: CheckoutAutomationType = {
  description: "",
  hasSetAmountOfTime: AutomationRadioGroup.NO,
  hasSpecificTime: AutomationRadioGroup.NO,
  name: "",
  setAmountOfTimeUnit: TimeUnitOption.HOURS,
  timeInterval: TimeInterval.DAILY,
  weeklyOnDay: WeeklySchedule.MONDAY,
};

const CreateAutomationCheckout: React.FC<Props> = ({ classes, notify, location }: Props) => {
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [checkoutAutomation, setCheckoutAutomation] = useState<CheckoutAutomationType>(defaultValue);
  const [showLoader, setShowLoaded] = useState(false);

  const isActionAvailable = location && location.state && Some(location.state.data) && Some(location.state.data.action);

  const goBack = () => {
    history.push("/workflows");
  };

  const {
    name,
    description,
    specificTime,
    timeInterval,
    weeklyOnDay,
    customDate,
    hasSpecificTime,
    hasSetAmountOfTime,
    setAmountOfTime,
    setAmountOfTimeUnit,
    automationCheckoutRule,
  } = checkoutAutomation;

  const isFormValid = useMemo(() => {
    if (!name.trim().length) {
      return false;
    }
    if (hasSpecificTime === AutomationRadioGroup.NO && hasSetAmountOfTime === AutomationRadioGroup.NO) {
      return false;
    }
    if (hasSpecificTime === AutomationRadioGroup.YES) {
      if (None(specificTime) || (Some(specificTime) && specificTime.length === 0)) {
        return false;
      }
      if (
        timeInterval === TimeInterval.WEEKLY &&
        (None(weeklyOnDay) || (Some(weeklyOnDay) && weeklyOnDay.length === 0))
      ) {
        return false;
      }
      if (
        timeInterval === TimeInterval.CUSTOM_DATE &&
        (None(customDate) || (Some(customDate) && customDate.length === 0))
      ) {
        return false;
      }
    }
    if (hasSetAmountOfTime === AutomationRadioGroup.YES) {
      if (None(setAmountOfTime) || (Some(setAmountOfTime) && setAmountOfTime.length === 0)) {
        return false;
      }
    }
    if (hasSpecificTime === AutomationRadioGroup.YES && hasSetAmountOfTime === AutomationRadioGroup.YES) {
      return Some(automationCheckoutRule) && automationCheckoutRule.length > 0;
    }

    return true;
  }, [
    name,
    specificTime,
    hasSpecificTime,
    hasSetAmountOfTime,
    setAmountOfTime,
    automationCheckoutRule,
    timeInterval,
    weeklyOnDay,
    customDate,
  ]);

  const isBeforeDateTime = useMemo(() => {
    if (
      Some(customDate) &&
      Some(specificTime) &&
      hasSpecificTime === AutomationRadioGroup.YES &&
      timeInterval === TimeInterval.CUSTOM_DATE
    ) {
      return moment(`${customDate} ${specificTime}`).toDate() < moment(new Date().setSeconds(0, 0)).toDate();
    }

    return false;
  }, [customDate, specificTime, hasSpecificTime, timeInterval]);

  const submitAutomation = async () => {
    const interval = timeInterval === TimeInterval.CUSTOM_DATE ? "date" : timeInterval;
    try {
      if (
        isActionAvailable &&
        Some(location.state.data.checkOutAutomation) &&
        Some(location.state.data.checkOutAutomation.checkOutAutomationId) &&
        location.state.data.action === "Edit"
      ) {
        setShowLoaded(true);
        await updateCheckOutAutomation(location.state.data.checkOutAutomation.checkOutAutomationId, {
          checkOutRule:
            hasSpecificTime === AutomationRadioGroup.YES && hasSetAmountOfTime === AutomationRadioGroup.YES
              ? automationCheckoutRule
              : undefined,
          customDate:
            hasSpecificTime === AutomationRadioGroup.YES && timeInterval === TimeInterval.CUSTOM_DATE
              ? customDate
              : undefined,
          description,
          hasSetAmountOfTime: hasSetAmountOfTime === AutomationRadioGroup.YES,
          hasSpecificTime: hasSpecificTime === AutomationRadioGroup.YES,
          name,
          setAmountOfTime: setAmountOfTime ? Number(setAmountOfTime) : 0,
          setAmountOfTimeUnit,
          specificTime: hasSpecificTime === AutomationRadioGroup.YES ? specificTime : undefined,
          timeInterval: hasSpecificTime === AutomationRadioGroup.YES ? interval : undefined,
          weeklyOnDay:
            hasSpecificTime === AutomationRadioGroup.YES && timeInterval === TimeInterval.WEEKLY
              ? weeklyOnDay
              : undefined,
          ...(hasSetAmountOfTime === AutomationRadioGroup.YES ? {} : { checkOutRule: null, setAmountOfTime: 0 }),
          ...(hasSpecificTime === AutomationRadioGroup.YES
            ? {}
            : { checkOutRule: null, customDate: null, specificTime: null }),
        });
        notify("Check-out automation updated successfully.", "success");
        history.replace("/workflows");
      } else {
        setShowLoaded(true);
        await createCheckOutAutomation({
          checkOutRule:
            hasSpecificTime === AutomationRadioGroup.YES && hasSetAmountOfTime === AutomationRadioGroup.YES
              ? automationCheckoutRule
              : undefined,
          customDate:
            hasSpecificTime === AutomationRadioGroup.YES && timeInterval === TimeInterval.CUSTOM_DATE
              ? customDate
              : undefined,
          description,
          hasSetAmountOfTime: hasSetAmountOfTime === AutomationRadioGroup.YES,
          hasSpecificTime: hasSpecificTime === AutomationRadioGroup.YES,
          name,
          setAmountOfTime: setAmountOfTime ? Number(setAmountOfTime) : 0,
          setAmountOfTimeUnit,
          specificTime: hasSpecificTime === AutomationRadioGroup.YES ? specificTime : undefined,
          timeInterval: hasSpecificTime === AutomationRadioGroup.YES ? interval : undefined,
          weeklyOnDay:
            hasSpecificTime === AutomationRadioGroup.YES && timeInterval === TimeInterval.WEEKLY
              ? weeklyOnDay
              : undefined,
        });
        notify("Check-out automation created successfully.", "success");
        history.replace("/workflows");
      }
    } catch (error: any) {
      const message = error.message ?? "Something went wrong while saving the check-out automation";
      notify(message, "error");
    } finally {
      setShowLoaded(false);
    }
  };

  const helperText = () => (
    <main>
      <Grid container spacing={0} justify="center" direction="column" className={classes.createAutomationText}>
        <p>
          For some Activities, it can be valuable to automate check-outs for participants. <br />
          Automating check-outs can ensure that people are checked out at appropriate times and maintains consistency
          within an Activity.
        </p>
        <br />
        <p>
          A <i>Workflow</i> can be added to an Activity during Activity-editing. People checked-in to that Activity will
          be checked-out in accordance with the <i>Workflow</i>.
        </p>
        <br />
        <p>
          Example:
          <br />A &quot;Check-out at 5pm&quot; workflow is added to the &apos;Shift B&apos; activity. <br />
          Based on the rules and settings of the workflow, people who check in to &apos;Shift B&apos; are automatically
          checked out at 5pm every day.
        </p>
      </Grid>
    </main>
  );

  const setAutomationDetails = (checkOutAutomation: CheckoutAutomationType) => {
    setCheckoutAutomation({
      ...defaultValue,
      automationCheckoutRule:
        (checkOutAutomation.checkOutRule as AutomationCheckoutRule) || defaultValue.automationCheckoutRule,
      customDate:
        checkOutAutomation.specificTime && Some(checkOutAutomation.customDate)
          ? checkOutAutomation.customDate.split("T")[0]
          : undefined,
      description: checkOutAutomation.description,
      hasSetAmountOfTime: checkOutAutomation.hasSetAmountOfTime
        ? AutomationRadioGroup.YES
        : defaultValue.hasSetAmountOfTime,
      hasSpecificTime: checkOutAutomation.hasSpecificTime ? AutomationRadioGroup.YES : defaultValue.hasSpecificTime,
      name: checkOutAutomation.name,
      setAmountOfTime: checkOutAutomation.setAmountOfTime
        ? String(checkOutAutomation.setAmountOfTime)
        : defaultValue.setAmountOfTime,
      setAmountOfTimeUnit:
        (checkOutAutomation.setAmountOfTimeUnit as TimeUnitOption) || defaultValue.setAmountOfTimeUnit,
      specificTime: checkOutAutomation.specificTime
        ? String(checkOutAutomation.specificTime)
        : defaultValue.specificTime,
      timeInterval: (checkOutAutomation.timeInterval as TimeInterval) || defaultValue.timeInterval,
      weeklyOnDay: (checkOutAutomation.weeklyOnDay as WeeklySchedule) || defaultValue.weeklyOnDay,
    });
  };

  useEffect(() => {
    if (isActionAvailable && Some(location.state.data.checkOutAutomation)) {
      setAutomationDetails(location.state.data.checkOutAutomation);
    }
  }, [location.state]);

  return (
    <div>
      <AppBar position="static" className={`innerAppbar appbar ${classes.appbarOveride}`}>
        <Grid container spacing={16}>
          <Grid item sm={8} className="create_title" data-testid="title-CreateAutomationCheckout">
            {isActionAvailable
              ? `${location.state.data.action} Check-out Automation`
              : "Create an Check-out Automation"}
            <Tooltip title={helperText()} overlayClassName={classes.innterTooltipOverride}>
              <img alt="" src={questionMark} style={{ paddingLeft: "8px" }} />
            </Tooltip>
          </Grid>
        </Grid>
      </AppBar>
      {showLoader && <LoadingScreen minHeight="calc(100vh - 125px)" />}
      <main className={classes.mainBackground} style={{ display: showLoader ? "none" : "block" }}>
        <div className={classes.createAutomation}>
          <Grid item className="align_left">
            <div className="paper_popup">
              <div className="grid grid_hr" style={{ padding: 16 }}>
                <h5>Configure the following settings to automate a check-out workflow</h5>
              </div>
            </div>
          </Grid>
          <Grid container spacing={16} justify="center" direction="column" className={classes.formControlContainer}>
            <Grid item className="align_left" xs={7}>
              <div className="paper_popup">
                <div className="grid">
                  <h6 className="">Automation Name:</h6>
                  <Grid container spacing={24} className={classes.formControl}>
                    <TextField
                      id="automation-name"
                      value={name}
                      onChange={(e) => setCheckoutAutomation({ ...checkoutAutomation, name: e.target.value })}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          input: classes.input,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      inputProps={{
                        maxLength: 60,
                      }}
                      data-testid="automationNameInputField-CreateAutomationCheckout"
                    />
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item className="align_left" xs={7} style={{ marginTop: "15px" }}>
              <div className="paper_popup">
                <div className="grid">
                  <h6 className="">Automation Description:</h6>
                  <Grid container spacing={24} className={classes.formControl}>
                    <TextField
                      id="automation-description"
                      multiline
                      rowsMax="3"
                      rows="3"
                      value={description}
                      onChange={(e) => setCheckoutAutomation({ ...checkoutAutomation, description: e.target.value })}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          input: classes.input,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      inputProps={{
                        maxLength: 300,
                      }}
                      data-testid="automationDescriptionInputField-CreateAutomationCheckout"
                    />
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item className="align_left" xs={12} style={{ marginLeft: -11, marginTop: "15px" }}>
              <div className="paper_popup">
                <div className="grid">
                  <h6 className="">
                    Do you want to check people out <u>at</u> a specific time?
                  </h6>
                  <Grid container spacing={24} className={classes.formControl}>
                    <RadioGroup
                      aria-label="SpecificTime"
                      name="hasSpecificTime"
                      value={hasSpecificTime}
                      onChange={(_, value: AutomationRadioGroup) =>
                        setCheckoutAutomation({ ...checkoutAutomation, hasSpecificTime: value })
                      }
                    >
                      <Grid container spacing={24}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={AutomationRadioGroup.YES}
                                checked={hasSpecificTime === AutomationRadioGroup.YES}
                                data-testid="atTime-YesRadioButton-CreateAutomationCheckout"
                              />
                            }
                            label="Yes"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={AutomationRadioGroup.NO}
                                checked={hasSpecificTime === AutomationRadioGroup.NO}
                                data-testid="atTime-NoRadioButton-CreateAutomationCheckout"
                              />
                            }
                            label="No"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {hasSpecificTime === AutomationRadioGroup.YES && (
                    <div className={classes.specificTimeRoot}>
                      <Grid container spacing={8}>
                        <Grid container item xs={12} spacing={24}>
                          <Grid item xs={4}>
                            <TextField
                              name="specificTime"
                              type="time"
                              label="Check out time"
                              value={specificTime}
                              className={classes.timeTextField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                              onChange={(e) =>
                                setCheckoutAutomation({ ...checkoutAutomation, specificTime: e.target.value })
                              }
                              data-testid="timeInputField-CreateAutomationCheckout"
                            />
                          </Grid>
                          <Grid direction="column" item xs={3}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel htmlFor="timeInterval-readonly">Frequency</InputLabel>
                              <Select
                                value={timeInterval}
                                name="TimeInterval"
                                onChange={(e) =>
                                  setCheckoutAutomation({
                                    ...checkoutAutomation,
                                    timeInterval:
                                      e.target.value === "custom date"
                                        ? ("date" as TimeInterval)
                                        : (e.target.value as TimeInterval),
                                  })
                                }
                                input={
                                  <Input
                                    id="timeInterval-readonly"
                                    style={{ textTransform: "capitalize" }}
                                    data-testid="timeIntervalInputField-CreateAutomationCheckout"
                                  />
                                }
                                MenuProps={MenuProps}
                              >
                                {Object.values(TimeInterval).map((value) => (
                                  <option
                                    value={value}
                                    className={classes.selectOption}
                                    data-testid={`${value}-Option-CreateAutomationCheckout`}
                                  >
                                    {value === "date" ? "custom date" : value}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            {timeInterval === TimeInterval.CUSTOM_DATE && (
                              <TextField
                                label="Custom Date"
                                type="date"
                                value={customDate}
                                className={classes.timeTextField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) =>
                                  setCheckoutAutomation({ ...checkoutAutomation, customDate: e.target.value })
                                }
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                                data-testid="customDateInputField-CreateAutomationCheckout"
                              />
                            )}
                            {timeInterval === TimeInterval.WEEKLY && (
                              <FormControl style={{ width: "100%" }}>
                                <InputLabel htmlFor="weeklyOnDay-readonly">Weekly On</InputLabel>
                                <Select
                                  value={weeklyOnDay}
                                  name="weeklyOnDay"
                                  onChange={(e) =>
                                    setCheckoutAutomation({
                                      ...checkoutAutomation,
                                      weeklyOnDay: e.target.value as WeeklySchedule,
                                    })
                                  }
                                  input={<Input id="weeklyOnDay-readonly" style={{ textTransform: "capitalize" }} />}
                                  MenuProps={MenuProps}
                                  data-testid="weekDropdown-CreateAutomationCheckout"
                                >
                                  {Object.values(WeeklySchedule).map((value) => (
                                    <option
                                      value={value}
                                      className={classes.selectOption}
                                      data-testid={`${value}-WeekDay-CreateAutomationCheckout`}
                                    >
                                      {value}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
              {isBeforeDateTime && (
                <FormHelperText
                  className={classes.formControl}
                  style={{ color: "red", fontSize: 14, marginLeft: 20 }}
                  data-testid="errorMessage-CreateAutomationCheckout"
                >
                  You are selecting a date and time that have already passed. People who have previously checked out
                  will not have their check-out automatically updated.
                </FormHelperText>
              )}
            </Grid>
            <Grid item className="align_left" xs={7} style={{ marginTop: "15px" }}>
              <div className="paper_popup">
                <div className="grid">
                  <h6 className="">
                    Do you want to check people out <u>after</u> a set amount of time?
                  </h6>
                  <Grid container spacing={24} className={classes.formControl}>
                    <RadioGroup
                      aria-label="AfterAmountOfTime"
                      name="afterAmountOfTime"
                      value={hasSetAmountOfTime}
                      onChange={(_, value: AutomationRadioGroup) =>
                        setCheckoutAutomation({ ...checkoutAutomation, hasSetAmountOfTime: value })
                      }
                    >
                      <Grid container spacing={24}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={AutomationRadioGroup.YES}
                                checked={hasSetAmountOfTime === AutomationRadioGroup.YES}
                                data-testid="afterTime-YesRadioButton-CreateAutomationCheckout"
                              />
                            }
                            label="Yes"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Radio
                                color="primary"
                                value={AutomationRadioGroup.NO}
                                checked={hasSetAmountOfTime === AutomationRadioGroup.NO}
                                data-testid="afterTime-NoRadioButton-CreateAutomationCheckout"
                              />
                            }
                            label="No"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  <Grid
                    container
                    spacing={16}
                    className={classes.formControl}
                    style={{
                      display: hasSetAmountOfTime === AutomationRadioGroup.YES ? "block" : "none",
                      marginLeft: "5px",
                      marginTop: "15px",
                    }}
                  >
                    <h6 className="" data-testid="hoursHeader-CreateAutomationCheckout">
                      Check-out after
                    </h6>
                    <TextField
                      name="setAmountOfTime"
                      value={setAmountOfTime}
                      onChange={(e) =>
                        setCheckoutAutomation({ ...checkoutAutomation, setAmountOfTime: e.target.value })
                      }
                      onKeyDown={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      margin="dense"
                      variant="outlined"
                      type="number"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputSetAmountOfTime,
                          notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                          <Select
                            value={setAmountOfTimeUnit}
                            name="setAmountOfTimeUnit"
                            onChange={(e) =>
                              setCheckoutAutomation({
                                ...checkoutAutomation,
                                setAmountOfTimeUnit: e.target.value as TimeUnitOption,
                              })
                            }
                            input={
                              <Input
                                id="setAmountOfTimeUnit-readonly"
                                style={{ textTransform: "capitalize" }}
                                data-testid="hoursDropdown-CreateAutomationCheckout"
                              />
                            }
                            MenuProps={MenuProps}
                            variant="outlined"
                          >
                            {Object.values(TimeUnitOption).map((value) => (
                              <option
                                value={value}
                                className={classes.selectOption}
                                data-testid={`${value}-TimeUnitOption-CreateAutomationCheckout`}
                              >
                                {value}
                              </option>
                            ))}
                          </Select>
                        ),
                      }}
                      inputProps={{
                        maxLength: 10,
                        min: 0,
                      }}
                      data-testid="hoursInputField-CreateAutomationCheckout"
                    />
                  </Grid>
                </div>
              </div>
            </Grid>
            {hasSpecificTime === AutomationRadioGroup.YES && hasSetAmountOfTime === AutomationRadioGroup.YES && (
              <Grid item className="align_left" xs={7} style={{ marginTop: "15px", paddingBottom: "15px" }}>
                <div className="paper_popup">
                  <div className="grid">
                    <h6
                      id="event-name-label"
                      className=""
                      data-testid="checkoutConditionHeader-CreateAutomationCheckout"
                    >
                      You have configured both automatic check out conditions. Confirm when to check a person out.
                    </h6>
                    <Grid container spacing={24} className={classes.formControl} style={{ padding: "20px" }}>
                      <RadioGroup
                        aria-label="AutomationCheckoutCondition"
                        name="automationCheckoutRule"
                        value={automationCheckoutRule}
                        onChange={(_, value: AutomationCheckoutRule) =>
                          setCheckoutAutomation({
                            ...checkoutAutomation,
                            automationCheckoutRule: value,
                          })
                        }
                      >
                        <FormControlLabel
                          value={AutomationCheckoutRule.FCFS}
                          control={
                            <Radio
                              color="primary"
                              checked={automationCheckoutRule === AutomationCheckoutRule.FCFS}
                              data-testid="firstComeRadioButton-CreateAutomationCheckout"
                            />
                          }
                          label="At Least One Met"
                        />
                        <FormHelperText className={classes.formHelperText}>
                          If at least one condition is met, check the person out
                        </FormHelperText>
                        <FormControlLabel
                          control={
                            <Radio
                              value={AutomationCheckoutRule.DESIGNATED_Date_Time}
                              color="primary"
                              checked={automationCheckoutRule === AutomationCheckoutRule.DESIGNATED_Date_Time}
                              data-testid="dateTimeRadioButton-CreateAutomationCheckout"
                            />
                          }
                          label="End Time Met"
                        />
                        <FormHelperText className={classes.formHelperText}>
                          Once the check out time has been met, check the person out (i.e. 15:01 is after 15:00)
                        </FormHelperText>
                        <FormControlLabel
                          control={
                            <Radio
                              value={AutomationCheckoutRule.REQUIRED_TIME_PASSED}
                              color="primary"
                              checked={automationCheckoutRule === AutomationCheckoutRule.REQUIRED_TIME_PASSED}
                              data-testid="requiredTimeRadioButton-CreateAutomationCheckout"
                            />
                          }
                          label="Time Passed Met"
                        />
                        <FormHelperText className={classes.formHelperText}>
                          Once the set amount of time has passed, check the person out (i.e. 8 hours)
                        </FormHelperText>
                        <FormControlLabel
                          control={
                            <Radio
                              value={AutomationCheckoutRule.ALL_TRUE}
                              color="primary"
                              checked={automationCheckoutRule === AutomationCheckoutRule.ALL_TRUE}
                              data-testid="allTrueRadioButton-CreateAutomationCheckout"
                            />
                          }
                          label="Both Conditions Met"
                        />
                        <FormHelperText className={classes.formHelperText}>
                          Both conditions must be met to check the person out
                        </FormHelperText>
                      </RadioGroup>
                    </Grid>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </main>
      <div className="innerAppbar appbar">
        <div className="mui-container mui--text-center" style={{ paddingTop: "1%" }}>
          <Button
            data-testid="backButton-CreateAutomationCheckout"
            variant="contained"
            color="secondary"
            style={{ float: "left" }}
            onClick={() => setShowConfirmationBox(true)}
          >
            Back
          </Button>
          <Button
            data-testid="publishButton-CreateAutomationCheckout"
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            disabled={!isFormValid}
            onClick={submitAutomation}
          >
            Publish
          </Button>
        </div>
      </div>
      <ConfirmationBox
        open={showConfirmationBox}
        closeConfirmation={() => setShowConfirmationBox(false)}
        goToPreviousPage={goBack}
        message="Your changes are not saved. Still want to go back?"
        testId="confirmationModal"
      />
    </div>
  );
};

const styles = () =>
  createStyles({
    appbarOveride: { height: "initial" },
    createAutomation: {
      background: "#ffffff",
      boxShadow: "0px 1px 0px #dce5ef",
      marginBottom: "50px",
    },
    createAutomationText: {
      color: "#ffffff",
      padding: "20px",
    },
    cssLabel: {
      color: "#687FA3",
    },
    formControl: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    formControlContainer: {
      padding: 20,
      paddingLeft: "30px !important",
      paddingRight: "30px !important",
    },
    formHelperText: {
      marginLeft: "33px !important",
      marginTop: "-8px !important",
    },
    innterTooltipOverride: {
      "& .ant-tooltip-inner": {
        backgroundColor: "rgba(0, 0, 0, 1)",
        width: "fit-content",
      },
    },
    input: {
      height: 10,
      width: 340,
    },
    inputSetAmountOfTime: {
      height: 10,
      width: 170,
    },
    mainBackground: {
      background: "#f5f6f8",
      height: "calc(100vh - 100px)",
      overflowY: "scroll",
      padding: "40px",
      paddingLeft: "229px !important",
      paddingRight: "229px !important",
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    selectOption: {
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 400,
      height: 30,
      paddingBottom: 10,
      paddingLeft: 8,
      textTransform: "capitalize",
    },
    specificTimeRoot: {
      flexGrow: 1,
      marginTop: 20,
    },
    timeTextField: {
      marginLeft: "10px",
      marginRight: "10px",
      width: 200,
    },
  });

export default withRouter(withStyles(styles)(CreateAutomationCheckout));
