/* eslint-disable react/no-multi-comp */
import "./EventCheckins.css";
import "./CheckinLogTable.css";
import { Button, Dropdown, Empty, Icon, Menu, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { ActionMenu } from "./ActionsMenu";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { Some } from "../../../utils/Some";
import action from "../../../assets/Images/Icons/action.svg";
import arrow from "../../../assets/Images/Icons/arrow-right.svg";
import download from "../../../assets/Images/Icons/download.svg";
import file from "../../../assets/Images/Icons/file-outline.svg";
import greenFile from "../../../assets/Images/Icons/greenFile.svg";
import meritStack from "../../../assets/Images/Icons/Merit Icon stacked.svg";
import moment from "moment-timezone";

const styles = StyleSheet.create({
  actionButton: {
    background: Colors.inputBGColor,
    border: "none",
    borderLeft: `1px solid ${Colors.blue}`,
    borderRadius: "0",
    boxShadow: "none",
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "12px",
    fontWeight: "bold",
    height: "40px",
    lineHeight: "14px",
    width: "115px",
  },
  actionTitle: {
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "10px",
    fontWeight: "bold",
    letterSpacing: "0.03em",
    lineHeight: "12px",
    paddingLeft: "16px",
    textTransform: "uppercase",
  },
  menuItem: {
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: 900,
    letterSpacing: "0.01em",
    lineHeight: "14px",
    paddingLeft: "16px",
  },
});

export interface Props {
  readonly checkins: EventAttendee[];
  readonly handleSelectRow: (eventAttendee: EventAttendee) => void;
  readonly handleSelectAllClick: (event: boolean) => void;
  readonly editCheckInCheckOutTime: (eventAttendee: EventAttendee, editType?: string) => void;
  readonly editMemberName: (eventAttendee: EventAttendee) => void;
  readonly handleOptionClick: (e: React.MouseEvent, option: string, eventAttendee: EventAttendee) => void;
  readonly downloadWaiver: (e: React.MouseEvent, eventAttendee: EventAttendee) => void;
  readonly handleClickAction: (event: boolean, eventAttendee: EventAttendee) => void;
  readonly handleMenuItemClick: (index: number) => void;
  readonly loading: boolean;
  readonly addNotesBulk: () => void;
  readonly onEditCheckInCheckOutTimeBulk: () => void;
  readonly onDeleteBulk: () => void;
  readonly onMatchCheckinLog: () => void;
  readonly pageNumber: number;
  readonly notify: (message: string, status: string) => void;
}
interface TableColumns {
  align?: "right";
  dataIndex: string;
  key: string;
  title: string | React.ReactNode;
  render?: (text: string | number, record?: EventAttendee) => React.ReactNode;
  width?: string;
  className?: string;
}

type SelectedRowKey = {
  email: string;
  eventAttendeeId: number;
  eventId: string;
  timezone: string;
};
const changeDateFormat = (checkIn: string, timeZone: string) => {
  if (checkIn !== null) {
    const date = moment(checkIn).tz(timeZone).format("MMM D, YYYY h:mm a z");

    return date;
  }

  return "";
};

const actionOptions = ["Add notes", "Match", "Delete"];
const dropdownMenus: string[] = ["Edit Record Times", "Add notes", "Match", "Delete check-in"];

export const CheckInLogTable: React.FC<Props> = ({
  checkins,
  handleSelectRow,
  editCheckInCheckOutTime,
  editMemberName,
  handleOptionClick,
  downloadWaiver,
  handleClickAction,
  handleMenuItemClick,
  loading,
  onEditCheckInCheckOutTimeBulk,
  onDeleteBulk,
  onMatchCheckinLog,
  addNotesBulk,
  pageNumber,
  notify,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [disableMatchOption, setDisableMatchOption] = useState<boolean>(false);

  useEffect(() => {
    if (pageNumber === 1) {
      setSelectedRowKeys([]);
    }
  }, [checkins]);

  const handleCheckout = (eventAttendee: EventAttendee) => {
    const checkoutComp = [];
    if (eventAttendee.event.isCheckOutReq || eventAttendee.checkOut !== null) {
      if (eventAttendee.checkOut) {
        checkoutComp.push(<div key="green" className="table-checkin-btn green" />);
        checkoutComp.push(
          <span key="green-span" className="cursor_pointer" onClick={() => editCheckInCheckOutTime(eventAttendee)}>
            {changeDateFormat(eventAttendee.checkOut, eventAttendee.checkOutTz)}
          </span>,
        );
        checkoutComp.push(<br key="green-br" />);
        checkoutComp.push(
          <div key="green-dur" className="table-checkout-subTitle">
            {eventAttendee.durationString}
          </div>,
        );
      } else {
        checkoutComp.push(<div key="orange" className="table-checkin-btn orange" />);
        checkoutComp.push(
          <a
            key="link"
            data-testid={`${eventAttendee.event.name}-Checkout-CheckInLogTable`}
            onClick={() => editCheckInCheckOutTime(eventAttendee)}
          >
            Check-out <img className="image_padding" src={arrow} alt="" />
          </a>,
        );
      }
    }

    return checkoutComp;
  };

  const handleMenuClick = (params: { readonly key: string }) => {
    switch (params.key) {
      case "0":
        onEditCheckInCheckOutTimeBulk();
        break;
      case "1":
        addNotesBulk();
        break;
      case "2":
        onMatchCheckinLog();
        break;
      case "3":
        onDeleteBulk();
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} style={{ width: "176px" }}>
      <p className={css(styles.actionTitle)}>Actions</p>
      {dropdownMenus.map((menu: string, index: number) => {
        return (
          <Menu.Item
            disabled={menu === "Match" && disableMatchOption}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={css(styles.menuItem)}
          >
            <span data-testid={`${menu}-Action-CheckInLogTable`}>{menu}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const memberTitle = (): React.ReactNode => {
    const selectedRowKeysLength = selectedRowKeys.length;

    return (
      <React.Fragment>
        <div data-testid="noOfCheckins-CheckInLogTable">
          {selectedRowKeysLength} of {checkins.length} selected
        </div>
        <Dropdown overlay={menu} disabled={selectedRowKeysLength === 0}>
          <Button className={css(styles.actionButton)} data-testid="actionsButton-CheckInLogTable">
            Actions <Icon type="caret-down" />
          </Button>
        </Dropdown>
      </React.Fragment>
    );
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header member_title",
      dataIndex: "member",
      key: "member",
      render: (text: string, eventAttendee: EventAttendee) => {
        return (
          <div onClick={() => editMemberName(eventAttendee)}>
            <div
              className="table-content-title-pointer"
              data-testid={`${eventAttendee.firstName}${eventAttendee.lastName}-ParticipantUserName-CheckInLogTable`}
            >{`${eventAttendee.firstName} ${eventAttendee.lastName}`}</div>
            <div
              className="table-content-subTitle"
              data-testid={`${eventAttendee.email}-ParticipantEmail-CheckInLogTable`}
            >
              {eventAttendee.email}
            </div>
          </div>
        );
      },
      title: memberTitle(),
      width: "280px",
    },
    {
      className: "checkin-table-header",
      dataIndex: "activity",
      key: "activity",
      render: (text: string, eventAttendee: EventAttendee) => (
        <div className="table-content-title" data-testid={`${eventAttendee.event.name}-Activity-CheckInLogTable`}>
          {eventAttendee.event.name}
        </div>
      ),
      title: "Activity",
      width: "25%",
    },
    {
      className: "table-content cursor_pointer checkin-table-header",
      dataIndex: "checkedIn",
      key: "checkedIn",
      render: (text: string, eventAttendee: EventAttendee) => {
        let checkinKioskName = eventAttendee.isManualCheckIn ? "Manual check-in" : eventAttendee.kiosk.name;

        if (eventAttendee.sessionLogId) {
          checkinKioskName += " (offline)";
        }

        return (
          <div onClick={() => editCheckInCheckOutTime(eventAttendee, "checkin")}>
            <div className="table-checkin-btn green" />
            <div
              className="table-content-title"
              data-testid={`${eventAttendee.firstName}-${eventAttendee.event.name}-Checkin-CheckInLogTable`}
            >
              {changeDateFormat(eventAttendee.checkIn!, eventAttendee.checkInTz)}
            </div>
            <div className="table-content-subTitle">{checkinKioskName}</div>
          </div>
        );
      },
      title: "Checked In",
      width: "18%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkedOut",
      key: "checkedOut",
      render: (text: string, EventAttendee: EventAttendee) => (
        <div>
          <div
            className="table-content-title"
            data-testid={`${EventAttendee.firstName}-${EventAttendee.event.name}-CheckedOut-CheckInLogTable`}
          >
            {handleCheckout(EventAttendee)}
          </div>
        </div>
      ),
      title: "Checked Out",
      width: "18%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "merits",
      key: "merits",
      render: (text: string, eventAttendee: EventAttendee) => {
        return (
          <div className="table-content-title">
            {eventAttendee.attendeeMerit !== "[]" && eventAttendee.attendeeMerit !== null ? (
              <img
                alt=""
                className="cursor_pointer"
                data-testid={`${eventAttendee.event.name}-QualificationRecordsIcon-CheckInLogTable`}
                src={meritStack}
                onClick={(e: React.MouseEvent) => handleOptionClick(e, "copyQualification", eventAttendee)}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
      title: "",
      width: "5%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "misc",
      key: "misc",
      render: (text: string, eventAttendee: EventAttendee) => {
        return (
          <div className="table-content-title">
            {!eventAttendee.isManualCheckIn && eventAttendee.waiverFilled ? (
              <Tooltip title="Download" placement="bottom">
                <img
                  alt=""
                  className="send cursor_pointer"
                  src={download}
                  onClick={(e) => downloadWaiver(e, eventAttendee)}
                />
              </Tooltip>
            ) : (
              <span className="table-download-spacing" />
            )}
            {eventAttendee.notes === null ? (
              <Tooltip title="notes" placement="bottom">
                <img
                  alt=""
                  className="add_note"
                  src={file}
                  onClick={(e: React.MouseEvent) => handleOptionClick(e, "addNotes", eventAttendee)}
                  data-testid="addNotesIcon-CheckInLogTable"
                />
              </Tooltip>
            ) : (
              <Tooltip title={eventAttendee.notes} placement="bottom">
                <img
                  alt=""
                  className="add_note"
                  src={greenFile}
                  onClick={(e: React.MouseEvent) => handleOptionClick(e, "addNotes", eventAttendee)}
                  data-testid="addNotesGreenIcon-CheckInLogTable"
                />
              </Tooltip>
            )}
          </div>
        );
      },
      title: "",
      width: "5%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "menu-action",
      key: "menu-action",
      render: (text: string, eventAttendee: EventAttendee) => {
        return (
          <div className="table-content-title">
            <Dropdown
              overlayClassName="all_checkins_action_menu"
              trigger={["click"]}
              overlay={
                <ActionMenu
                  eventAttendee={eventAttendee}
                  actionOptions={actionOptions}
                  handleSelectMenu={(index) => handleMenuItemClick(index)}
                />
              }
              placement="bottomCenter"
              onVisibleChange={(event) => handleClickAction(event, eventAttendee)}
            >
              <img
                alt=""
                src={action}
                className="checkinlog_cursor_pointer"
                data-testid={`${eventAttendee.firstName}${eventAttendee.lastName}-ActionIcon-CheckInLogTable`}
              />
            </Dropdown>
          </div>
        );
      },
      title: "",
      width: "7%",
    },
  ];

  const rowSelection = {
    onSelect: (eventAttendee: EventAttendee, select: boolean, selectedRows: EventAttendee[]) => {
      const {
        email,
        eventAttendeeId,
        event: { eventId, timezone },
      } = eventAttendee;
      const selectRowsArray: SelectedRowKey[] = selectedRowKeys.map((row) => JSON.parse(row));
      if (select) {
        const userSelectedForSameEvent = selectRowsArray.find((e) => e.email === email && e.eventId === eventId);

        if (Some(userSelectedForSameEvent)) {
          notify("You cannot select same user for same event", "error");

          return;
        }

        const userSelectedForDifferentEventTimezone = selectRowsArray.find((e) => e.timezone !== timezone);

        if (Some(userSelectedForDifferentEventTimezone)) {
          notify("You cannot select checkin for different timezones", "error");

          return;
        }
        setSelectedRowKeys([...selectedRowKeys, JSON.stringify({ email, eventAttendeeId, eventId, timezone })]);
      } else {
        const selectRowKeys = selectRowsArray
          .filter((rowKey) => rowKey.eventAttendeeId !== eventAttendeeId)
          .map((row) => JSON.stringify(row));
        setSelectedRowKeys(selectRowKeys);
      }
      handleSelectRow(eventAttendee);
      const {
        event: { isCheckOutReq },
        checkOut,
      } = selectedRows[0];
      if (selectedRows.length !== 1 || !isCheckOutReq || Some(checkOut)) {
        setDisableMatchOption(true);
      } else {
        setDisableMatchOption(false);
      }
    },
    /*
    onSelectAll: (selected: boolean, selectedRowKeys: EventAttendee[]) => {
      const selectedRow = selectedRowKeys.map(({ email, eventAttendeeId, event: { eventId, timezone } }) =>
        JSON.stringify({ email, eventAttendeeId, eventId, timezone }),
      );

      // TODO:
      // TOOLS-1222: https://claimyourmerit.atlassian.net/browse/TOOLS-1222
      // 1. check for same user and same event
      // 2. check for timezone
      setSelectedRowKeys(selectedRow);
      handleSelectAllClick(selected);
    },
    */
    selectedRowKeys,
  };

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={checkins}
      pagination={false}
      className="check_ins_log_table"
      loading={loading}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span data-testid="noData-CheckInLogTable">No Data</span>}
          />
        ),
      }}
      rowKey={(eventAttendee) =>
        JSON.stringify({
          email: eventAttendee!.email,
          eventAttendeeId: eventAttendee!.eventAttendeeId,
          eventId: eventAttendee!.event.eventId,
          timezone: eventAttendee!.event.timezone,
        })
      }
    />
  );
};
