export const setUserScopedToken = (token: string) => {
  localStorage.setItem("userScopedToken", token);
};

export const getUserScopedToken = () => {
  return localStorage.getItem("userScopedToken");
};

export const removeUserScopedToken = () => {
  localStorage.removeItem("userScopedToken");
};
