import "./Offline.css";
import { Dropdown, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { deleteOfflineLog, downloadOfflineLog, fetchSessions } from "../../../constant/api";
import { ActionMenu } from "./ActionsMenu";
import { DeleteSessionModal } from "./Modals/DeleteSessionModal";
import { MatchCheckoutModal } from "./Modals/MatchCheckoutModal";
import { None } from "../../../utils/None";
// eslint-disable-next-line no-unused-vars
import { PaginationConfig } from "antd/lib/table/interface";
import action from "../../../assets/Images/Icons/action.svg";

type State = {
  readonly isLoading: boolean;
  readonly sessionLogs: SessionType[];
  readonly pagination?: PaginationConfig;
};

type Props = {
  readonly notify: (message: string, status: string) => void;
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string;
  readonly render?: (text: string, event: SessionType) => React.ReactNode;
  readonly className: string;
};

type ActionType = "Download" | "Delete" | "Match";

export const OfflineLogTable = ({ notify }: Props) => {
  const [tableState, setTableState] = useState<State>({
    isLoading: false,
    sessionLogs: [],
  });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showMatchCheckoutModal, setShowMatchCheckoutModal] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>("");

  const getSessions = useCallback(async (pagination: PaginationConfig) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      if (None(pagination.current)) {
        throw new Error(`Incomplete pagination object passed: ${JSON.stringify(pagination)}`);
      }

      const sessionsData = await fetchSessions(pagination.current);

      setTableState({
        isLoading: false,
        pagination: {
          ...pagination,
          pageSize: sessionsData.data.metadata.pageSize,
          total: sessionsData.data.metadata.total,
        },
        sessionLogs: sessionsData.data.item,
      });
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching the sessions", "error");
    }
  }, []);

  useEffect(() => {
    getSessions({ current: 1 });
  }, [getSessions]);

  const deleteLog = async (sessionId: string) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      await deleteOfflineLog(sessionId);

      setShowDeleteModal(false);
      setSessionId("");

      notify("Checkin deleted successfully.", "success");

      await getSessions({ ...tableState.pagination });

      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Unable to delete this session.", "error");
    }
  };

  const downloadLog = async (sessionId: string) => {
    try {
      const response = await downloadOfflineLog(sessionId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers.filename);
      const body: HTMLElement = document.body;
      body.appendChild(link);
      link.click();
    } catch (error) {
      notify("Download failed", "error");
    }
  };

  const onMenuItemClick = (selectedOption: ActionType, offlineData: SessionType) => {
    if (selectedOption === "Download") {
      downloadLog(offlineData.sessionId);
    } else if (selectedOption === "Delete") {
      setSessionId(offlineData.sessionId);
      setShowDeleteModal(true);
    } else {
      setSessionId(offlineData.sessionId);
      setShowMatchCheckoutModal(true);
    }
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header",
      dataIndex: "kioskName",
      title: "Tablet Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "sessionId",
      title: "Session ID",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkIns",
      title: "Check ins",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkOuts",
      title: "Check outs",
    },
    {
      className: "checkin-table-header",
      dataIndex: "startDateTime",
      title: "Offline start",
    },
    {
      className: "checkin-table-header",
      dataIndex: "endDateTime",
      title: "Offline end",
    },
    {
      className: "checkin-table-header",
      dataIndex: "menu-action",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, offlineData) => {
        return (
          <Dropdown
            overlayClassName="all_offline_action_menu"
            trigger={["click"]}
            overlay={
              <ActionMenu
                actionOptions={["Delete", "Download", "Match"]}
                onSelect={(selectedOption: ActionType) => onMenuItemClick(selectedOption, offlineData)}
              />
            }
            placement="bottomLeft"
          >
            <img alt="" src={action} className="checkinlog_cursor_pointer" />
          </Dropdown>
        );
      },
      title: "",
    },
  ];

  return (
    <>
      <Table
        loading={tableState.isLoading}
        dataSource={tableState.sessionLogs}
        columns={columns}
        rowKey="sessionId"
        onChange={(pagination) => {
          getSessions(pagination);
        }}
        pagination={{ ...tableState.pagination }}
      />

      {showDeleteModal && (
        <DeleteSessionModal
          onClose={() => {
            setShowDeleteModal(false);
            setSessionId("");
          }}
          isVisible={showDeleteModal}
          onDelete={() => deleteLog(sessionId)}
        />
      )}

      {showMatchCheckoutModal && (
        <MatchCheckoutModal
          onClose={() => {
            setShowMatchCheckoutModal(false);
            setSessionId("");
          }}
          isVisible={showMatchCheckoutModal}
          notify={notify}
          sessionId={sessionId}
        />
      )}
    </>
  );
};
