import React, { PureComponent } from "react";
import { Switch, withStyles } from "@material-ui/core";

interface Props {
  readonly id: string;
  readonly name: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly handleChange: (data: { name: string; val: boolean }) => void;
  readonly classes: {
    readonly colorSwitchBase: string;
    readonly colorBar: string;
    readonly colorChecked: string;
  };
}
interface State {}

const styles = {
  colorBar: {},
  colorChecked: {},
  colorSwitchBase: {
    "&$colorChecked": {
      "& + $colorBar": {
        backgroundColor: "#36802d",
      },
      color: "#36802d",
    },
  },
};

class Switches extends PureComponent<Props, State> {
  state = {};

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { name: name, val: event.target.checked };
    const { handleChange } = this.props;
    handleChange(data);
  };

  render() {
    const { name, checked, classes, disabled } = this.props;

    return (
      <div id="switch-div">
        <Switch
          data-testid={name}
          id={name}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={this.handleChange(name)}
          classes={{
            bar: classes.colorBar,
            checked: classes.colorChecked,
            switchBase: classes.colorSwitchBase,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Switches);
