import "react-toastify/dist/ReactToastify.css";
import "../components/UI-Components/CommonComponents.css";
import * as React from "react";
// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../store/store";
import { ERROR_MSG_SHOW_DURATION, SUCCESS_MSG_SHOW_DURATION } from "../../config/constants";
import { createStyles, withStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import AdminLogWrapper from "./Web/Offline/AdminLogWrapper";
import AllCheckins from "../components/Web/EventCheckins/AllCheckins";
import { BookmarkComponent } from "./Checkin-Web/Bookmark";
import BulkCheckinsWrapper from "./Web/CheckinBulk";
import ChooseActivities from "./Web/Kiosk/ChooseActivities";
import Cookies from "js-cookie";
import CreateAutomationCheckout from "./Web/Workflows/CheckoutAutomation/CreateAutomationCheckout";
import CreateEvent from "./Web/Event/CreateEvent/CreateEvent";
import CreateQualification from "../components/Web/Qualification/createQualification";
import CreateWaiver from "../components/Web/Waiver/CreateWaiver";
import { Dashboard } from "./Web/Dashboard/Dashboard";
import Events from "./Web/Event/Events";
import Header from "../components/UI-Components/Header";
import HomePage from "../components/Web/Home/HomePage";
import Kiosk from "../components/Web/Kiosk/Kiosk";
import { Layout } from "antd";
import { LinkExpired } from "./Checkin-Web/LinkExpired";
import LoadingScreen from "../components/UI-Components/LoadingScreen";
import { None } from "../utils/None";
import OrgLoginSuccess from "./OrgLoginSuccess";
import PreviewWaiver from "./Web/Waiver/PreviewWaiver";
import Qualification from "../components/Web/Qualification/qualification";
import { Route } from "react-router-dom";
import ViewActivities from "./Web/Kiosk/ViewActivities";
import VirtualKioskName from "./Web/Kiosk/VirtualKioskName";
import WaiverWelcome from "../components/Web/Waiver/WaiverWelcome";
import { WebCheckinRoute } from "./Checkin-Web/WebCheckinRoute";
import Workflows from "./Web/Workflows";
import { getOrganizationDetail } from "../constant/api";
import history from "../history";
import { toast } from "react-toastify";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useMeritAuth0 } from "../hooks/auth";

interface Props {
  readonly orgDetails: any;
  readonly location: {
    readonly path: string;
    readonly pathname: string;
  };
  readonly classes: {
    readonly root: string;
    readonly layout1: string;
    readonly layout2: string;
    readonly layout3: string;
    readonly layout4: string;
    readonly kiosklayout: string;
  };
}

interface State {
  readonly width: string;

  orgDetails: any;
  reload: boolean;
}

const selectedOrgSelector = (state: AppState) => state.selectedOrg;

const Home = (props: Props) => {
  const [showBookMarkComponent, setShowBookMarkComponent] = useState(false);
  const selectedOrg = useStore(selectedOrgSelector);
  const { accessToken, isAuthenticated, isLoading } = useMeritAuth0();
  const [homePageState, setHomePageState] = useState<State>({
    orgDetails: null,
    reload: false,
    width: "calc(100% - 240px)",
  });

  const virtualKioskIdentification = Cookies.get("virtualKioskIdentification");
  const virtualKioskType = Cookies.get("virtualKioskType");
  const { errorHandler } = useDefaultErrorHandler();
  const hasFetchedOrgDetails = useRef(false);

  useEffect(() => {
    if (
      !hasFetchedOrgDetails.current &&
      ((accessToken && selectedOrg && isAuthenticated) || (virtualKioskIdentification && !isLoading))
    ) {
      getOrganizationDetails();
      hasFetchedOrgDetails.current = true;
    } else if (isInvalidKioskPage()) {
      setShowBookMarkComponent(true);
    } else if (!isLoading && !isAuthenticated) {
      history.push("/");
    }

    if (isAuthenticated && !selectedOrg && !virtualKioskIdentification) {
      history.push("/");
    }
  }, [accessToken, isLoading, isAuthenticated, selectedOrg, virtualKioskIdentification]);

  const isVirtualKioskCookiesNotExists = () => {
    return None(virtualKioskIdentification) || None(virtualKioskType);
  };

  const isInvalidKioskPage = () => {
    return (
      isVirtualKioskCookiesNotExists() &&
      (window.location.href.includes("web-checkin/home") ||
        window.location.href.includes("/web-checkin/selectactivity") ||
        window.location.href.includes("web-checkin/checkInWith"))
    );
  };

  const getOrganizationDetails = async () => {
    if (isInvalidKioskPage()) {
      return;
    }

    try {
      const response: any = await getOrganizationDetail();
      setOrgDetail(response.data);
    } catch (error) {
      if (window.location.href.includes("linkexpired")) {
        history.push("/web-checkin/linkexpired");
      } else {
        errorHandler(error);
      }
    }
  };
  const { classes, location } = props;

  useEffect(() => {
    (window as any).previousLocation = location;
  }, [location]);

  const notify = (message: string, status: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    toast[status](<span data-testid={`${message}-Toast-Home`}>{message}</span>, {
      autoClose: status === "error" ? ERROR_MSG_SHOW_DURATION : SUCCESS_MSG_SHOW_DURATION,
      closeOnClick: true,
      draggable: true,
      hideProgressBar: true,
      pauseOnHover: true,
      position: "top-right",
    });
  };

  // to refresh the list of member after manual check-in
  const getAllCheckins = () => {
    if (window.location.pathname === "/members") {
      setHomePageState((prevState) => ({ ...prevState, reload: !prevState.reload }));
    }
  };

  const setOrgDetail = async (orgDetails: {
    logoUrl: string;
    name: string;
    readonly sigmaOrgId: string;
    allowOfflineMode: boolean;
  }) => {
    setHomePageState((prevState) => ({ ...prevState, orgDetails }));
  };

  const handleLoadingScreen = () => {
    if (window.location.href.includes("linkexpired")) {
      return (
        <div className="webcheckin">
          <Layout style={{ background: "none" }}>
            <LinkExpired />
          </Layout>
        </div>
      );
    }

    return <LoadingScreen minHeight="100vh" />;
  };
  const { orgDetails, width } = homePageState;
  // To Do - change routing according to layout

  // TODO: restructure routing, generalise the layout and render based on layout of page

  if (showBookMarkComponent || isInvalidKioskPage()) {
    return <BookmarkComponent />;
  }

  return (
    <div className={classes.root}>
      {orgDetails
        ? [
            <Route
              exact
              path="/create-event"
              component={(): any => [
                <main className={classes.layout3}>
                  <CreateEvent
                    notify={notify}
                    allowOfflineMode={orgDetails.allowOfflineMode}
                    checkInMTId={orgDetails.checkInMTId}
                  />
                </main>,
              ]}
              key="create-event"
            />,
            <Route
              exact
              path="/events"
              component={(): any => [
                <Header
                  key="events-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="events-wrapper">
                  <Events notify={notify} />
                </main>,
              ]}
              key="events"
            />,
            <Route
              exact
              path="/members"
              component={(): any => [
                <Header
                  key="members-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="members-wrapper">
                  <AllCheckins notify={notify} allowOfflineMode={orgDetails.allowOfflineMode} />
                </main>,
              ]}
              key="members"
            />,
            <Route
              exact
              path="/waivers"
              component={(): any => [
                <Header
                  key="waivers-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="waivers-wrapper">
                  <WaiverWelcome />
                </main>,
              ]}
              key="waivers"
            />,
            <Route
              exact
              path="/create-waiver"
              component={() => (
                <div className={classes.layout3}>
                  <CreateWaiver notify={notify} />
                </div>
              )}
              key="create-waiver"
            />,
            <Route
              exact
              path="/preview"
              component={() => (
                <main className={classes.layout2}>
                  <PreviewWaiver />
                </main>
              )}
              key="preview"
            />,
            <Route
              exact
              path="/qualification"
              component={(): any => [
                <Header
                  key="qualification-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="qualification-wrapper">
                  <Qualification />
                </main>,
              ]}
              key="qualification"
            />,
            <Route
              exact
              path="/create-qualification"
              component={() => (
                <main className={classes.layout3}>
                  <CreateQualification notify={notify} orgDetails={orgDetails} />
                </main>
              )}
              key="create-qualification"
            />,
            <Route
              exact
              path="/welcome"
              component={(): any => [
                <Header
                  key="welcome-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="welcome-wrapper">
                  <HomePage orgName={orgDetails.name} />
                </main>,
              ]}
              key="welcome"
            />,

            <Route
              exact
              path="/kiosk"
              component={(): any => [
                <Header
                  key="kiosk-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout4} key="kiosk-wrapper">
                  <Kiosk notify={notify} />
                </main>,
              ]}
              key="kiosk"
            />,
            <Route
              exact
              path="/workflows"
              component={(): any => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Header
                    key="workflows-header"
                    width={width}
                    activeNav={location.pathname}
                    orgDetails={orgDetails}
                    getAllCheckins={getAllCheckins}
                    notify={notify}
                  />
                  <main className={classes.layout4} key="workflows-wrapper" style={{ zIndex: 0 }}>
                    <Workflows notify={notify} />
                  </main>
                </div>
              )}
              key="workflows"
            />,
            <Route
              exact
              path="/virtualkioskname"
              component={(): any => (
                <main className={classes.kiosklayout}>
                  <VirtualKioskName notify={notify} />
                </main>
              )}
              key="virtual-kiosk-name"
            />,
            <Route
              exact
              path="/home"
              component={(): any => [
                <Header
                  key="home-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout4} key="home-wrapper">
                  <Dashboard orgDetails={orgDetails} />
                </main>,
              ]}
              key="home"
            />,
            <Route
              exact
              path="/admin-log"
              component={(): any => [
                <Header
                  key="admin-log-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="admin-log-wrapper">
                  <AdminLogWrapper allowOfflineMode={orgDetails.allowOfflineMode} notify={notify} />
                </main>,
              ]}
              key="admin-log"
            />,
            <Route
              exact
              path="/chooseactivities"
              component={(): any => (
                <Layout>
                  <ChooseActivities notify={notify} />
                </Layout>
              )}
              key="choose-activities"
            />,
            <Route
              exact
              path="/viewactivities"
              component={(): any => (
                <Layout>
                  <ViewActivities />
                </Layout>
              )}
              key="view-activities"
            />,
            <WebCheckinRoute key="web-checkin" orgDetails={orgDetails} notify={notify} />,
            <Route
              exact
              path="/bulk-checkins"
              component={(): any => [
                <Header
                  key="bulk-checkins-header"
                  width={width}
                  activeNav={location.pathname}
                  orgDetails={orgDetails}
                  getAllCheckins={getAllCheckins}
                  notify={notify}
                />,
                <main className={classes.layout1} key="bulk-checkins-wrapper">
                  <BulkCheckinsWrapper notify={notify} />
                </main>,
              ]}
              key="bulk-checkins"
            />,
            <Route
              exact
              path="/create-automation-checkout"
              component={(): any => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <main className={classes.layout4} key="create-automation-checkout-wrapper" style={{ zIndex: 0 }}>
                    <CreateAutomationCheckout notify={notify} />
                  </main>
                </div>
              )}
              key="create-automation-checkout"
            />,
            <Route
              exact
              path="/org-login-success"
              component={(): any => [
                <main className={classes.layout1} key={19.1}>
                  <OrgLoginSuccess />
                </main>,
              ]}
              key={19}
            />,
          ]
        : handleLoadingScreen()}
    </div>
  );
};

const styles = (theme: any) =>
  createStyles({
    appBarSpacer: theme.mixins.toolbar,
    kiosklayout: {
      flexGrow: 1,
      paddingBottom: 0,
      paddingTop: 0,
    },
    layout1: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      paddingBottom: 0,
      paddingTop: 0,
    },
    layout2: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      flexGrow: 1,
    },
    layout3: {
      background: "F0F7FE",
      flexGrow: 1,
      minHeight: "100vh",
    },
    layout4: {
      flexGrow: 1,
    },
    root: {
      backgroundColor: "#F5F6F8",
    },
  });

export default withStyles(styles)(Home);
