// Copyright 2024 Merit International Inc. All Rights Reserved

import React, { useEffect } from "react";
import { useLogout, useMeritAuth0 } from "../hooks/auth";
import LoadingScreen from "./UI-Components/LoadingScreen";
import { None } from "../utils/None";
import { Some } from "../utils/Some";
import history from "../history";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useStore } from "../store/store";
import { webLoginSuccess } from "../constant/api";

const OrgLoginSuccess = () => {
  const { isAuthenticated, user, isLoading } = useMeritAuth0();
  const { selectedOrg, setIntermediateScreen, setLandingPagePath } = useStore();
  const logout = useLogout();
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      logout();
    }

    if (None(selectedOrg)) {
      setIntermediateScreen("selectOrg");

      return;
    }

    /**
     * This check ensures that personal tokens do not work in this component.
     * If the user is not associated with any organization, they are logged out.
     */
    if (None(user) || (Some(user) && None(user.org_uuid))) {
      logout();

      return;
    }

    const performWebLoginSuccess = async () => {
      try {
        const redirectPath = await webLoginSuccess(selectedOrg.id);
        setIntermediateScreen(undefined);
        setLandingPagePath(redirectPath);
        history.push(redirectPath);
      } catch (error) {
        errorHandler(error);
      }
    };

    if (user.org_uuid === selectedOrg.id) {
      performWebLoginSuccess();
    } else {
      logout();
    }
  }, [isAuthenticated, isLoading]);

  return <LoadingScreen minHeight="100vh" />;
};

export default OrgLoginSuccess;
