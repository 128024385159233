import { useCallback, useState } from "react";
import Cookies from "js-cookie";
import { Some } from "../utils/Some";
import axios from "axios";
import { showErrorToast } from "./ToastHelper";
import { useLogout } from "../hooks/auth";
import { useStore } from "../store/store";

/*
 * A wrapper around the prevalent Error handling that stores Error in state
 * and throws it during Render, so as to be caught by ErrorBoundary
 */
export const useDefaultErrorHandler = () => {
  const [error, setError] = useState<unknown>();
  const logoutAuth0 = useLogout();

  const errorHandler = useCallback(
    async (err: unknown) => {
      if (axios.isAxiosError(err) && (err as any).response.status === 401) {
        if (!window.location.pathname.includes("web-checkin")) {
          Cookies.remove("auth");
        }
        logoutAuth0((url: string) => {
          // Clear the App state
          if (window.location.pathname.includes("web-checkin")) {
            useStore.getState().setIntermediateScreen(undefined);
          } else {
            useStore.getState().clearAppState();
          }
          showErrorToast({
            message: err.message,
            onClose: () => {
              window.location.assign(url);
            },
          });
        });
      } else {
        setError(err);
      }
    },
    [setError],
  );

  if (Some(error)) {
    throw error;
  }

  return { errorHandler };
};
