import { Menu } from "antd";
import React from "react";

type ActionType = "Download" | "Delete" | "Match";

export interface Props {
  readonly actionOptions: ActionType[];
  readonly onSelect: (option: ActionType) => void;
}

export const ActionMenu = ({ actionOptions, onSelect }: Props) => {
  return (
    <Menu>
      {actionOptions.map((option) => {
        return (
          <Menu.Item key={option} onClick={() => onSelect(option)} className="menu_item">
            <span style={{ fontSize: "16px" }}>{option}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
