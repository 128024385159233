import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { None } from "../../../../utils/None";
import Select from "react-select";
import { Some } from "../../../../utils/Some";
import Switches from "../../../UI-Components/Switches";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";

interface Props {
  readonly handleChange: (data: { name: string; val: string | boolean }) => void;
  readonly handleClickEvent: (boolean: boolean) => void;
  readonly eventList: EventList[];
  readonly dependentEventId: number;
  readonly hasDependentEvent: boolean;
  readonly selectedEvent?: EventList;
  readonly message: string;
  readonly noPasswordCheckin: boolean;
}

export function EventDependentActivity({
  selectedEvent: selectedDependentEvent,
  dependentEventId,
  eventList,
  handleChange,
  handleClickEvent,
  hasDependentEvent,
  noPasswordCheckin,
  message,
}: Props) {
  const [showEventsDropdown, setShowEventDropDown] = useState(hasDependentEvent);
  const [selectedEvent, setSelectetdEvent] = useState<any>(undefined);
  useEffect(() => {
    if (Some(dependentEventId) && dependentEventId && hasDependentEvent) {
      setShowEventDropDown(true);
      setSelectetdEvent(eventList.find((event) => event.eventId === dependentEventId));
    }
  }, [dependentEventId, eventList, hasDependentEvent]);

  const handleChangeSwitch = (data: { name: string; val: boolean }) => {
    setShowEventDropDown(data.val);
    handleChange(data);
    handleClickEvent(data.val);
  };

  const onSelectChange = (eventData: any) => {
    const data: { name: string; val: string } = {
      name: "dependentEventId",
      val: eventData.eventId,
    };
    handleChange(data);
    setSelectetdEvent(eventData);
    handleClickEvent(true);
  };

  const filteredDependentEventList = () => {
    return eventList.filter((option) => {
      const isSameEvent = option.eventId === selectedDependentEvent?.eventId;
      const isDependent = option.hasDependentEvent && option.dependentEventId === selectedDependentEvent?.eventId;

      return !(isSameEvent || isDependent);
    });
  };

  return (
    <Row className="grid grid_hr">
      <Row>
        <Col md={15} lg={14} xl={9} xxl={7}>
          <h6 className="step4_subtitle margin_4_top">
            Does this activity depend on another activity
            <Tooltip placement="top" title="Enable Dependent Activity">
              <img alt="" src={questionMark} className="padding_8_left" />
            </Tooltip>
          </h6>
        </Col>
        <Col span={1}>
          <Row className="step2_switch2 margin_0_top">
            <Switches
              id="hasDependentEvent"
              name="hasDependentEvent"
              handleChange={handleChangeSwitch}
              checked={showEventsDropdown}
            />
          </Row>
        </Col>
      </Row>
      {showEventsDropdown && (
        <>
          <Row>
            <Grid item xs={5}>
              <h6 className="margin_0_top margin_10_bottom" data-testid="activitiesText-EventDependentActivity">
                Activities
              </h6>
              <div data-testid="selectActivityDropdown-EventDependentActivity">
                <Select
                  isClearable
                  isSearchable
                  name="dependentEventId"
                  value={selectedEvent}
                  options={filteredDependentEventList()}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => String(option.eventId)}
                  onChange={onSelectChange}
                />
              </div>
              <h6 className="margin_10 time-zone-text">
                Checkin to the activity from dropdown is prerequiste before checkin to the current activity
              </h6>
              <span
                className="error-msg highlight"
                id="event-name-error"
                data-testid="errorMessage-EventDependentActivity"
              >
                {message}
              </span>
            </Grid>
          </Row>

          <Row>
            <Grid>
              <Row>
                <Col md={15} lg={14} xl={9} xxl={7}>
                  <h6 className="step4_subtitle margin_10">
                    Remove password requirement for activity check-in?
                    <Tooltip
                      placement="top"
                      title="Turning this setting on makes it so people checking in to this activity with a static QR code are not required to input a password for a successful check-in"
                    >
                      <img alt="" src={questionMark} className="padding_8_left" />
                    </Tooltip>
                  </h6>
                </Col>
                <Col span={1}>
                  <Row className="step2_switch2 margin_0_top">
                    <Switches
                      id="noPasswordCheckin"
                      name="noPasswordCheckin"
                      handleChange={handleChange}
                      checked={noPasswordCheckin}
                      disabled={None(selectedEvent)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={15} lg={14} xl={9} xxl={7}>
                  <p
                    className="margin_0 time-zone-text no-password-sub-text"
                    data-testid="passwordLessCheckinInfoText-EventDependentActivity"
                  >
                    Enabling password-less check-in has security risks, as it relies on less secure one-factor
                    authentication. It should only be used for secure, private, and closed events.
                  </p>
                </Col>
              </Row>
            </Grid>
          </Row>
        </>
      )}
    </Row>
  );
}
