/* eslint-disable no-unused-vars */
import { Button, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Dropdown, Menu } from "antd";
import { Colors } from "../../../../theme/Colors";
import { Fonts } from "../../../../theme/Fonts";
import React from "react";
import history from "../../../../history";

interface ActivitiesPopUpProps {
  activities: Array<EventCheckOutAutomationMapping & { event: EventList }>;
  checkoutAutomation: CheckOutAutomationWithEvent[];
  checkOutAutomationId?: number;
}
interface Props extends ActivitiesPopUpProps, WithStyles<typeof styles> {}

const ActivitiesPopUp = ({ activities, classes, checkoutAutomation, checkOutAutomationId }: Props) => {
  const editActivity = (dataObj: EventList & { originSource: string }) => {
    history.push({
      pathname: "/create-event",
      state: { data: dataObj },
    });
  };

  const handleActivityClick = (event: EventList) => {
    const eventCheckOutAutomationMapping = checkoutAutomation.reduce(
      (requiredAutomations: EventCheckOutAutomationMapping[], { eventCheckOutAutomationMapping }) => {
        return [
          ...requiredAutomations,
          ...eventCheckOutAutomationMapping.filter(
            ({ event: { eventId } }) => eventId === event.eventId && event.isCheckOutReq,
          ),
        ];
      },
      [],
    );

    editActivity({
      ...event,
      eventCheckOutAutomationMapping,
      originSource: "/workflows",
      title: "Edit activity",
    });
  };

  const activitiesButtonDataTestId = `${
    checkoutAutomation.find((i) => i.checkOutAutomationId === checkOutAutomationId)?.name
  }-Length-ActivitiesPopUp`;

  return (
    <>
      {activities.length ? (
        <Dropdown
          overlayClassName="all_checkins_action_menu"
          trigger={["click"]}
          overlay={
            <Menu className={classes.list}>
              <h6
                className={`${classes.textAlignLeft}
                ${classes.menuHeader}
                ${classes.automationText}`}
                data-testid="header-ActivitiesPopUp"
              >
                Set on the following activities
              </h6>

              {activities.map(({ eventCheckOutAutomationId, event }, index) => (
                <Menu.Item
                  key={eventCheckOutAutomationId + event.name}
                  onClick={() => handleActivityClick(event)}
                  className="menu_item"
                  data-testid={`${event.name}-Menu-ActivitiesPopUp`}
                >
                  {`${index + 1}. ${event?.name}`}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomCenter"
        >
          <Button className={classes.activitiesButton} data-testid={activitiesButtonDataTestId}>
            {activities.length}
          </Button>
        </Dropdown>
      ) : (
        <p className={classes.zeroActivity}>{activities.length}</p>
      )}
    </>
  );
};

const styles = () =>
  createStyles({
    activitiesButton: {
      color: Colors.blueColumnText,
      cursor: "pointer",
      fontFamily: Fonts.default,
      fontSize: 16,
      fontWeight: "normal",
      minWidth: 20,
      textDecoration: "underline",
    },
    activitiesList: { color: Colors.blue, cursor: "pointer", fontSize: "16px", textDecoration: "underline" },
    automationText: { margin: "0" },
    list: {
      borderRadius: 4,
      maxHeight: 160,
      overflowY: "auto",
      paddingBottom: 8,
      paddingTop: 8,
      width: 230,
    },
    menuHeader: { fontSize: "13px", fontWeight: 600, padding: "12px 16px" },
    textAlignLeft: { textAlign: "left" },
    zeroActivity: {
      color: "rgb(0,0,0,0.65)",
      display: "inline-block",
      fontFamily: Fonts.default,
      fontSize: 16,
      marginBottom: 0,
      paddingTop: 5,
    },
  });

export default withStyles(styles)(ActivitiesPopUp);
