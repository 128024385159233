// Copyright 2024 Merit International Inc. All Rights Reserved

import { LogStatus, logger } from "../utils/DDLoggerHelper";
import { asyncWithLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import type { LDFeatureFlags } from "../config/featureFlags";
import { None } from "../utils/None";
import { featureFlagDefaults } from "../config/featureFlags";

export const useLaunchDarkly = () => {
  const { LAUNCH_DARKLY_CLIENT_ID } = process.env;
  const [LDProvider, setLdProvider] = useState<({ children }: { readonly children: React.ReactNode }) => JSX.Element>();
  const [isLoading, setIsLoading] = useState(true);

  if (None(LAUNCH_DARKLY_CLIENT_ID)) {
    return { LDProvider, isLoading };
  }

  useEffect(() => {
    const initializeLaunchDarkly = async () => {
      try {
        const ldProvider = await asyncWithLDProvider({
          clientSideID: LAUNCH_DARKLY_CLIENT_ID,
        });

        setLdProvider(() => ldProvider);
        setIsLoading(false);
      } catch (error) {
        logger("Failed to initialize LaunchDarkly", LogStatus.ERROR, {
          error,
        });
        setIsLoading(false);
      }
    };

    initializeLaunchDarkly();
  }, []);

  return { LDProvider, isLoading };
};

export const useFeatureFlag = () => {
  const featureFlags = useFlags<LDFeatureFlags>();

  return { ...featureFlagDefaults, ...featureFlags };
};
