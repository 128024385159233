import {
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { Some } from "../../../../utils/Some";
import moment from "moment-timezone";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";
import { updateCheckinTime } from "../../../../constant/api";

interface Props {
  readonly show: boolean;
  readonly notify: (message: string, status: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly info: string;
    readonly root: string;
    readonly table: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: EventAttendee[];
  currentDate: string;
  checkInTz: string | null;
}
interface State {
  checkInTime: string;
  error: string;
  failedAttendees: EventAttendee[];
}

class CheckinEdit extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentDate, eventAttendee } = this.props;
    this.state = {
      checkInTime: eventAttendee.length === 1 ? currentDate : "",
      error: "",
      failedAttendees: [],
    };
  }

  checkIn = async () => {
    const { error, checkInTime, failedAttendees } = this.state;
    const { checkInTz, eventAttendee, notify, handleClose, clearEventAttendeeIds, selectedAttendees } = this.props;
    if (error.length > 0) {
      return;
    }

    const eventAttendeeIds =
      failedAttendees.length > 0
        ? failedAttendees.map(({ eventAttendeeId }: EventAttendee) => eventAttendeeId)
        : eventAttendee;
    try {
      const params = {
        checkIn: Some(checkInTz) ? moment.tz(checkInTime, checkInTz).utc().format() : checkInTime,
        eventAttendeeId: eventAttendeeIds,
      };
      const response = await updateCheckinTime(params);
      const failedAttendeeIds = response.data
        .filter(({ status }: CheckInUpdate) => status === "failed")
        .map(({ eventAttendeeId }: CheckInUpdate) => eventAttendeeId);

      const failedAttendees = selectedAttendees.filter(({ eventAttendeeId }: EventAttendee) =>
        failedAttendeeIds.includes(eventAttendeeId),
      );

      if (failedAttendees.length === 0) {
        notify("Check-in update successful", "success");
        handleClose();
        clearEventAttendeeIds();
      } else {
        this.setState({
          error: "Check-in update failed for below persons",
          failedAttendees: failedAttendees,
        });
      }
    } catch (err) {
      notify(err.message || "Check-in update failed", "error");
      handleClose();
      clearEventAttendeeIds();
    }
  };

  // Managing time zones for a single event attendee check-in
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checkInTz } = this.props;
    const { value } = event.target;
    if (!value) {
      this.setState({
        checkInTime: value,
        error: "Please select valid date",
      });

      return;
    }

    const currentDateCondition = Some(checkInTz)
      ? moment.tz(value, checkInTz).isAfter(moment.tz(new Date(), checkInTz))
      : new Date(value) > new Date();

    if (currentDateCondition) {
      this.setState({
        checkInTime: value,
        error: "The Date and Time should be less than the current date.",
      });

      return;
    }

    this.setState({
      checkInTime: value,
      error: "",
    });
  };
  render() {
    const { classes, selectedAttendees: rows, show, handleClose } = this.props;
    const { error, checkInTime, failedAttendees } = this.state;
    const eventAttendees = failedAttendees.length > 0 ? failedAttendees : rows;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={show}
        disableBackdropClick
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item>
              <Tooltip
                placement="bottom-start"
                title={
                  "Pressing 'Check-in' checks the participant at the current time. You can edit the check-in within the log."
                }
              >
                <h4 className="modal-title">
                  Activity Check-In
                  <img alt="question-mark" src={questionMark} style={{ paddingLeft: "8px" }} />
                </h4>
              </Tooltip>
              <h6 className="modal_text">Check-In the selected people</h6>
            </Grid>
            <Grid item className="image_padding">
              <TextField
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  "data-testid": "checkinDateInputField-CheckInEditModal",
                }}
                value={checkInTime}
                onChange={this.handleChange}
              />
            </Grid>
            <span className="error-msg highlight" id="waiver-error">
              {error}
            </span>
            <Grid item className={classes.info}>
              <h6>
                <b>Note:</b> The check-in time should be in the activity&#39;s timezone.
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className="common_buttons"
                style={{ float: "right", marginTop: "15px" }}
                onClick={this.checkIn}
                disabled={error.length > 0 || checkInTime.length === 0}
                data-testid="updateCheckinButton-CheckInEditModal"
              >
                Check In
              </Button>
            </Grid>
          </Grid>
          {eventAttendees.length !== 0 && (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="record_data">Full Name</TableCell>
                    <TableCell className="record_data">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventAttendees.map(({ eventAttendeeId, firstName, lastName, email }: EventAttendee) => (
                    <ShowEventAttendeeDetails
                      eventAttendeeId={eventAttendeeId}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </Modal>
    );
  }
}

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssFocused: {
      color: "#687FA3",
    },
    cssLabel: {
      color: "#687FA3",
    },
    info: {
      marginTop: 20,
      paddingLeft: 7,
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 55,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });

export default withStyles(styles)(CheckinEdit);
